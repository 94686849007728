body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-repeat: repeat-y;
  font-family: sans-serif;
  color: #404756;
  font-size: 18px;
  line-height: 140%;
  background-color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  box-sizing: border-box;
}

body>iframe:last-of-type {
  display: none;
}

.socialMedia {
  background: lightred;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic"),
    url('./assets/fonts/CenturyGothic.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic BO"),
    url('./assets/fonts/GOTHICB0.TTF') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic BO";
  src: local("Century Gothic BO"),
    url('./assets/fonts/GOTHICB0.TTF') format("truetype");
}

@font-face {
  font-family: "Nielmaden";
  src: local('Nielmaden Regular'), local('Nielmaden-Regular'),
      url('./assets/fonts/Nielmaden-Regular.woff2') format('woff2'),
      url('./assets/fonts/Nielmaden-Regular.woff') format('woff'),
      url('./assets/fonts/Nielmaden-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nielmaden';
  src: local('Nielmaden SemiBold'), local('Nielmaden-SemiBold'),
      url('./assets/fonts/Nielmaden-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Nielmaden-SemiBold.woff') format('woff'),
      url('./assets/fonts/Nielmaden-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nielmaden';
  src: local('Nielmaden Bold'), local('Nielmaden-Bold'),
      url('./assets/fonts/Nielmaden-Bold.woff2') format('woff2'),
      url('./assets/fonts/Nielmaden-Bold.woff') format('woff'),
      url('./assets/fonts/Nielmaden-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nielmaden';
  src: local('Nielmaden Extra Bold'), local('Nielmaden-Extra-Bold'),
      url('./assets/fonts/Nielmaden-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/Nielmaden-ExtraBold.woff') format('woff'),
      url('./assets/fonts/Nielmaden-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

.fw-700 {
  font-weight: 700;
}

.fw-400 {
  font-weight: 400;
}

.ff-BO {
  font-family: "Century Gothic BO";
}

.ff-CG {
  font-family: "Century Gothic";
}


.ff-NL {
  font-family: "Nielmaden";
}

.CTA {
  color:
    #FD7835
}

#underline {
  border-bottom: 2px solid #0A3047;
}

#discord-btn {
  padding: 10px 20px;
  /* min-height: 45px; */
  max-height: 200px;
  border: none;
  background: #FD7835;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Century Gothic';
  font-size: 18px;
  font-weight: 700;
  background: #fd7835;
  text-decoration: none;

}


@media screen and (max-width: 800px) {
  .hidden-mobile {
    display: none;
  }
}

i/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
