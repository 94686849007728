.buy-eth {
    border: none;
    padding: 8.74582px 24.4883px;
    gap: 8.75px;
    width: 245px;
    height: 38.68px;
    background: #4C0D18;
    border-radius: 10.495px;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 15.7425px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.confirm {
    width: 176px;
    height: 64.98px;
    left: 635px;
    top: 963px;
    background: #FD7835;
    border-radius: 16.2462px;
    border: none;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 24.3692px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;

}