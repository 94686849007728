#btn {
  padding: 10px 28px;
  min-height: 45px;
  max-height: 200px;
  left: 613px;
  top: 56px;
  border: none;
  background: #FD7835;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Century Gothic';
  font-size: 18px;
  font-weight: 700;
  background: #fd7835;
}