.buy-dollar {
    border: none;
    padding: 8.74582px 24.4883px;
    width: 245px;
    height: 38.68px;
    background: #FD7835;
    border-radius: 10.495px;
    font-family: 'Century Gothic' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 15.7425px !important;
    line-height: 140% !important;
    text-align: center !important;
    color: #FFFFFF;
    background: #FD7835 !important;
    text-transform: uppercase;
    cursor: pointer;

}

.buy-dollar>img {
    display: none;
}