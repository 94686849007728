// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
}

.glide {
    position: relative;

    button {
        padding: 0;
        border: none;
        line-height: 0;
        background: none;
        cursor: pointer;
    }

    .glide__bullets {
        display: flex;
        margin-top: 60px;
        justify-content: center;
        column-gap: 28px;

        @media (max-width: 800px) {
            display: none;
        }

        .glide__bullet {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #D9D9D9;

            &.glide__bullet--active {
                background-color: #FD7835;
            }
        }
    }

    .glide__arrows {
        left: 0;
        top: 43%;
        width: 100%;
        display: flex;
        position: absolute;
        justify-content: space-between;

        @media (max-width: 800px) {
            top: 50%
        }


        .glide__arrow--left {
            transform: translateX(calc(-100% - 30px)) rotate(180deg);

            @media (max-width: 800px) {
                transform: translateX(-60%) rotate(180deg);
            }
        }

        .glide__arrow--right {
            transform: translateX(calc(100% + 30px));

            @media (max-width: 800px) {
                transform: translateX(60%);
            }
        }
    }
}